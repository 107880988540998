<template>
  <!-- Heading Area-->
  <div class="order-lg-2">
    <div class="heading-area pl-lg-4 p-0" :class="get_css_direction_classes">
      <h3 class="sub-title main-color lalezar-regular">{{ $t("company_name") }}</h3>
      <h2 class="title lalezar-regular">{{ $t("products.intro.our_products") }}</h2>
      <p class="paragraph lalezar-regular">
        {{ $t("products.intro.line_1") }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "products_intro",
  computed: {
    ...mapGetters(['get_css_direction_classes'])
  },
}
</script>

<style scoped>

</style>