<template>
  <div id="app">

    <!-- Preloader -->
    <div class="preloader">
      <div class="centrize full-width">
        <div class="vertical-center">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Preloader End -->

    <!-- Header -->
    <sidebar_main/>

    <div class="signle-layout">
      <!-- Site Main -->
      <div class="main-left" id="main">

        <!-- Page changer wrapper -->
        <div class="pt-wrapper">
          <!-- Subpages -->
          <div class="subpages">
            <!-- Home -->
            <home_main/>

            <!-- Products -->
            <products_main ref="products_main" />

            <!-- Services -->
            <services_main />

            <!-- Portfolio -->
            <section id="portfolio" class="pt-page pt-page-4" data-id="portfolio">
              <div class="container">
                <div class="row align-items-lg-center">

                  <!-- Portfolio Box-->
                  <div class="col-lg-12">
                    <!--Portfolio Items-->
                    <div id="js-grid-mosaic-flat" class="cbp cbp-l-grid-mosaic-flat">

                      <!-- Item -->
                      <div class="cbp-item">
                        <!--                        <a href="/static/personal/img/port-1.jpg" class="cbp-caption cbp-lightbox">-->
                        <!--                          <div class="cbp-caption-defaultWrap">-->
                        <!--                            <img src="{% static 'personal/img/port-1.jpg' %}" alt="port-1">-->
                        <!--                          </div>-->
                        <!--                          <div class="cbp-caption-activeWrap">-->
                        <!--                            <i class="lni-arrow-right p-icon"></i>-->
                        <!--                            <div class="port-content">-->
                        <!--                              <p class="mb-1 main-color">Elegent | Images</p>-->
                        <!--                              <h5>Latest Work</h5>-->
                        <!--                            </div>-->
                        <!--                          </div>-->

                        <!--                        </a>-->
                      </div>
                      <!-- Item -->
                      <div class="cbp-item">
                        <!--                        <a href="{% static 'personal/img/port-2.jpg' %}" class="cbp-caption cbp-lightbox">-->
                        <!--                          <div class="cbp-caption-defaultWrap">-->
                        <!--                            <img src="{% static 'personal/img/port-2.jpg' %}" alt="port-1">-->
                        <!--                          </div>-->
                        <!--                          <div class="cbp-caption-activeWrap">-->
                        <!--                            <i class="lni-arrow-right p-icon"></i>-->
                        <!--                            <div class="port-content">-->
                        <!--                              <p class="mb-1 main-color">Elegent | Images</p>-->
                        <!--                              <h5>Latest Work</h5>-->
                        <!--                            </div>-->
                        <!--                          </div>-->

                        <!--                        </a>-->
                      </div>
                      <!-- Item -->
                      <div class="cbp-item">
                        <!--                        <a href="{% static 'personal/img/port-3.jpg' %}" class="cbp-caption cbp-lightbox">-->
                        <!--                          <div class="cbp-caption-defaultWrap">-->
                        <!--                            <img src="{% static 'personal/img/port-3.jpg' %}" alt="port-1">-->
                        <!--                          </div>-->
                        <!--                          <div class="cbp-caption-activeWrap">-->
                        <!--                            <i class="lni-arrow-right p-icon"></i>-->
                        <!--                            <div class="port-content">-->
                        <!--                              <p class="mb-1 main-color">Elegent | Images</p>-->
                        <!--                              <h5>Latest Work</h5>-->
                        <!--                            </div>-->
                        <!--                          </div>-->

                        <!--                        </a>-->
                      </div>
                      <!-- Item -->
                      <div class="cbp-item">
                        <!--                        <a href="{% static 'personal/img/port-4.jpg' %}" class="cbp-caption cbp-lightbox">-->
                        <!--                          <div class="cbp-caption-defaultWrap">-->
                        <!--                            <img src="{% static 'personal/img/port-4.jpg' %}" alt="port-1">-->
                        <!--                          </div>-->
                        <!--                          <div class="cbp-caption-activeWrap">-->
                        <!--                            <i class="lni-arrow-right p-icon"></i>-->
                        <!--                            <div class="port-content">-->
                        <!--                              <p class="mb-1 main-color">Elegent | Images</p>-->
                        <!--                              <h5>Latest Work</h5>-->
                        <!--                            </div>-->
                        <!--                          </div>-->

                        <!--                        </a>-->
                      </div>
                      <!-- Item -->
                      <div class="cbp-item">
                        <!--                        <a href="{% static 'personal/img/port-5.jpg' %}" class="cbp-caption cbp-lightbox">-->
                        <!--                          <div class="cbp-caption-defaultWrap">-->
                        <!--                            <img src="{% static 'personal/img/port-5.jpg' %}" alt="port-1">-->
                        <!--                          </div>-->
                        <!--                          <div class="cbp-caption-activeWrap">-->
                        <!--                            <i class="lni-arrow-right p-icon"></i>-->
                        <!--                            <div class="port-content">-->
                        <!--                              <p class="mb-1 main-color">Elegent | Images</p>-->
                        <!--                              <h5>Latest Work</h5>-->
                        <!--                            </div>-->
                        <!--                          </div>-->

                        <!--                        </a>-->
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </section>

            <!-- Clients -->
            <clients_main ref="clients_main" />

            <!-- Contacts -->
            <contact_main/>

            <!-- AboutUs -->
            <about_main/>

          </div>
          <!-- End of Home Subpage -->

        </div>

      </div>
      <!-- Site Main End -->
    </div>
  </div>
</template>

<script>
import sidebar_main from './components/sidebar_main'
import home_main from './components/home_main'
import products_main from './components/products/products_main'
import services_main from './components/services_main'
import clients_main from './components/clients_main'
import contact_main from './components/contact_main'
import about_main from './components/about_main'

export default {
  name: 'App',
  components: {
    sidebar_main,
    home_main,
    products_main,
    services_main,
    clients_main,
    contact_main,
    about_main
  }
}
</script>

<style></style>
