<template>
  <div class="row categories-card" style="gap: 1rem;" :class="get_css_direction_classes">

    <template v-if="is_small_screen">
      <div v-for="category in categories"
           :key="category.id"
           class="card col-sm-12 col-md-3" style="width: 18rem;"
      >
        <img class="card-img-top" :src="category.primary_image"
             alt="Card image">
        <div class="card-body">
          <h1 class="card-title text-center text-light font-weight-400">{{ category.name }}</h1>
          <p class="card-text">{{ category.description }}</p>
          <button @click="$parent.get_category(category.id)" class="view-btn p-3 d-block">View Details</button>
        </div>
        <div class="custom-border"></div>

      </div>
    </template>

    <template v-else>
      <div v-for="category in categories"
           :key="category.id + 1" >
        <div class="flip-card-wrapper">
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <div class="image-wrapper">
                  <img :src="category.primary_image" alt="Card image" class="card-img">
                </div>

                <!--            <div class="title-wrapper">-->
                <!--              <p class="card-title">{{ category.name }}</p>-->
                <!--            </div>-->

              </div>
              <div class="flip-card-back">
                <h1 class="mt-3 category-name lalezar-regular">{{ category.name }}</h1>
                <p class="category-description lalezar-regular">{{ category.description }}</p>
                <button @click="$parent.get_category(category.id)" class="view-btn p-3 d-block btn-block"
                        style="border-radius: unset">{{ $t("products.view_details") }}
                </button>

              </div>
            </div>
          </div>
          <div class="name-wrapper">
            <h1 @click="$parent.get_category(category.id)" class="text-center mt-3 lalezar-regular card-name">{{  category.name }}</h1>
          </div>

        </div>

      </div>

    </template>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "categories_card",
  props: ['categories'],
  computed: {
    ...mapGetters(['get_css_direction_classes']),
    is_small_screen() {
      return window.innerWidth <= 768;
    },
  }
}
</script>

<style scoped>

</style>