<template>
  <section id="services" class="pt-page pt-page-3" data-id="services">
    <div class="container">
      <div class="row align-items-lg-center">
        <!-- Features Box-->
        <div class="col-lg-5 pr-lg-5">
          <div class="heading-area">
            <h6 class="sub-title main-color">Gulf Paper Company&#169; L.C </h6>
            <h2 class="title lalezar-regular" :class="get_css_direction_classes">{{ $t("services.our_services") }} </h2>
          </div>
          <!-- Progress Bar -->
          <ul class="services-skill text-left">
            <!--Progress Item-->
            <li v-for="(service, index) in services" :key="index" class="progress-item" :class="get_css_direction_classes">
              <h6 :class="get_css_direction_classes">
                {{ $t(service.label) }}
                <span :class="get_count_style()"><b class="count">{{ service.progress }}</b>%</span>
              </h6>
              <div class="progress">
                <span class="progress-bar" role="progressbar" :style="{ width: service.progress + '%' }" aria-valuemin="0" aria-valuemax="100"></span>
              </div>
            </li>
          </ul>

          <!--Counters Area-->
          <div class="counter-area">
            <!-- Counter Item-->
            <div class="counters">
              <i class="lni-apartment c-icon"></i>
              <!-- Content -->
              <div class="content">
                <h4 class="m-0 lalezar-regular"><span class="count">3</span>K</h4>
                <p class="lalezar-regular">{{ $t("services.Customer") }}</p>
              </div>
            </div>
            <!-- Counter Item-->
            <div class="counters">
              <i class="lni-calculator c-icon"></i>
              <!-- Content -->
              <div class="content">
                <h4 class="m-0 lalezar-regular"><span class="count">70</span>K</h4>
                <p class="lalezar-regular">{{ $t("services.Projects") }}</p>
              </div>
            </div>
          </div>

        </div>

        <!-- Heading Area-->
        <div class="col-lg-7">
          <div class="services-about">
            <img class="service-image" src="/static/personal/img/services_back_image.png" alt="image">
            <div class="content">
              <p>
                {{ $t("services.card_1.line_1") }}
                <br><br>
                {{ $t("services.card_1.line_2") }}
              </p>
              <h5 class="mb-1">Mahmoud Hussain</h5>
              <span>CEO, Gulf Paper</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "services_main",
  computed: {
    ...mapGetters(['get_css_direction_classes']),
  },
  data(){
    return {
      services: [
        { label: "services.manufacturing", progress: 99 },
        { label: "services.printing", progress: 100 },
        { label: "services.quality_and_technology", progress: 99 },
        { label: "services.customization", progress: 99 },
        { label: "services.sustainability", progress: 99 },
        { label: "services.support_and_service", progress: 99 }
      ],
    }
  },
  methods: {
    get_count_style() {
      let style = this.get_css_direction_classes
      if (this.get_css_direction_classes === 'arabic') {
        style +=  ' float-left'
      } else if (this.get_css_direction_classes === 'english') {
        style += ' float-right'
      }
      return style
    },
  }
}
</script>

<style scoped>

</style>