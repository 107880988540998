export default {
    company_name: "شركة أوراق الخليج المحدودة",
    sidebar: {
        home: "الرئيسية",
        products: "المنتجات",
        services: "الخدمات",
        clients: "العملاء",
        Contact: "تواصل معنا",
        about_us: "حول",
    },
    home: {
        name: "الرئيسية",
        // company_name: `GULF PAPER <span class="main-color">Company </span> <span> L.C </span>`,
        company_name: ` شركة <span class="main-color"> أوراق الخليج </span>  المحدودة`,
        intro: `نحن نفخر بتقديم مجموعة متنوعة من <span class="main-color">الملصقات المطبوعة </span>
 و <span class="main-color">الأوراق الحرارية </span> لتلبية احتياجاتك المتنوعة.
`,
        read_more: " << قراءة المزيد",
    },
    products: {
        name: "المنتجات",
        search_placeholder: "ابحث عن منتج",
        view_details: "عرض التفاصيل",
        intro: {
            our_products: "منتجاتنا",
            line_1: "نحن نعتني بالتفاصيل لنقدم لكم منتجات فريدة ومخصصة تناسب رؤيتكم",
        },
    },
    services: {
        our_services: "خدماتنا",
        manufacturing: "التصنيع",
        printing: "الطباعة",
        quality_and_technology: "الجودة والتكنولوجيا",
        customization: "التخصيص",
        sustainability: "الاستدامة والبيئة",
        support_and_service: "الدعم و الخدمة",

        Customer: "عميل",
        Projects: "مشروع",

        card_1: {
            line_1: "في مصنعنا، نحن متخصصون في تصنيع الملصقات الورقية والحرارية والبلاستيكية، بالإضافة إلى الورق الحراري، مع تقديم مجموعة واسعة من الأحجام والمواد لتلبية جميع احتياجات عملائنا .",
            line_2: "نضمن منتجات عالية الجودة مصممة لتعزيز هوية علامتك التجارية وتحسين كفاءة عملياتك .",
        }

    },
    clients: {
        get_to_know_our_clients: "تعرف علي عملائنا",
        our_clients_and_faq: "عملاؤنا والاسئلة الشائعة",
        line_1: "نحن نعمل معكم بشكل وثيق لضمان تلبية توقعاتكم وتحقيق رضاكم",
    },
    contact: {
        message_sent_successfully: "تم ارسال رسالتك بنحاج سنقوم بالرد عليكم في اقرب وقت ممكن !",
        ok: "تم",
        have_any_question: "لديك اي سؤال ؟",
        lets_get_in_touch: "اترك لنا رسالة",
        send_message: "ارسال",
        address: "العنوان",
        address_body: ` المملكة العربية السعودية – الرياض – طريق الخرج – مخرج 5 – الرفايع – المنطقة الصناعية`,
        email: "البريد الالكتروني",
        phone: "الهاتف",
        name: "الاسم",
        subject: "بخصوص",
        message: "الرسالة",
    },
    about: {
        line_1: {
            head: "من نحن",
            body: `
            
            مرحبًا بكم في شركة أوراق الخليج المحدودة ، رواد في مجال تصنيع وطباعة رولات الملصقات والأوراق الحرارية .
تأسست شركتنا في 1 يناير 2024 ، برؤية تهدف إلى توفير حلول الصناعة والطباعة المبتكرة وعالية الجودة والتي تلبي متطلبات السوق المتنامية .
نحن ملتزمون بتقديم منتجات تتميز بالدقة والكفاءة ، مستخدمين في ذلك أحدث التقنيات في عالم الصناعة و الطباعة . 
منذ تأسيسنا ، سعينا لأن نكون الخيار الأول لعملائنا ، ونعمل الآن على توسيع بصمتنا في الأسواق الإقليمية والدولية ، مدفوعين بشغفنا بالجودة والابتكار
قصتنا 
بدأت شركتنا مسيرتها في قلب المملكة العربية السعودية ، مدفوعة برؤية 2030 لتحقيق التميز في الصناعة والطباعة . 
نحن نستخدم أحدث التقنيات وأفضل المواد لضمان إنتاج رول ملصقات وأوراق حرارية ذات جودة عالية.
نسعى لأن نكون شركاء نجاح لعملائنا ، مقدمين منتجات تساعدهم على تعزيز هويتهم التجارية وتحسين كفاءة عملياتهم .
                        `,
        },
        line_2: {
            head: "قصتنا",
            body: "بدأت شركتنا رحلتها في قلب المملكة العربية السعودية مدفوعة برؤية 2030 لتحقيق التميز في الصناعة والطباعة. نحن نستخدم أحدث التقنيات وأفضل المواد لضمان إنتاج لفائف الملصقات والأوراق الحرارية عالية الجودة. نهدف إلى أن نكون شركاء ناجحين لعملائنا، حيث نقدم المنتجات التي تعزز هوية علامتهم التجارية وتحسن كفاءة عملياتهم.",
        },
        line_3: {
            head: "مهمتنا",
            body: "مهمتنا هي توفير حلول مبتكرة تساعد عملائنا على تحقيق أهدافهم الاستراتيجية .",
            body_2: "نسعى لأن نكون شركاء نجاح لعملائنا، مقدمين خدمات ومنتجات تتميز بالجودة العالية والكفاءة التشغيلية.",
            why_choose_gulfpaper: "لماذا تختار شركة أوراق الخليج المحدودة ؟",
            why_1: `
             - تخصيص المنتجات :  نقدم منتجات مخصصة بمختلف المقاسات لتلبية احتياجات عملائنا الفريدة
            `,
            why_2: `
            - الجودة العالية :  منتجاتنا تعكس التزامنا بالجودة الاستثنائية والدقة في التفاصيل
            `,
            why_3: `
             - الابتكار والتكنولوجيا : نستخدم أحدث التقنيات في تصنيع وطباعة الملصقات والأوراق الحرارية
            `,
        },
        line_4: {
            head: "اتصل بنا",
            body: `
            ندعوك للتواصل معنا لاستكشاف كيف يمكننا دعم أعمالك من خلال حلولنا المتطورة .
            فريق شركة أوراق الخليج المحدودة مستعد دائمًا لتقديم المشورة والدعم اللازم لضمان نجاحك .
            `,
        },
    },

    pagination: {
        previous: "السابق",
        next: "التالي",
    },
};
