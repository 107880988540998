<template>
  <div class="row mt-3 product-card" style="gap: 1rem;" :class="get_css_direction_classes">
    <template v-if="is_small_screen">
      <div
          v-for="product in products"
          :key="product.id"
          class="card col-sm-12 col-md-3" style="width: 18rem;"
      >
        <img class="card-img-top" :src="product.productimage_set.length > 0 ? product.productimage_set[0].image : '' "
             alt="product image ">
        <div class="card-body">
          <h1 class="card-title text-center text-light font-weight-400">{{ product.name }}</h1>
          <p class="card-text">{{ product.description }}</p>
          <button @click="$parent.get_product(product.id)" class="view-btn p-3 d-block">View Details</button>
        </div>
        <div class="custom-border"></div>

      </div>
    </template>

    <template v-else>
      <div v-for="product in products"
           :key="product.id + 1" >
        <div class="flip-card-wrapper" >
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <div class="image-wrapper">
                  <img :src="product.productimage_set.length > 0 ? product.productimage_set[0].image : '' " alt="Card image"
                       class="card-img">
                </div>
<!--                <div class="title-wrapper">-->
<!--                  <p class="card-title">{{ product.name }}</p>-->
<!--                </div>-->
              </div>
              <div class="flip-card-back">
                <h1 class="mt-3 product-name lalezar-regular">{{ product.name }}</h1>
                <p class="category-description lalezar-regular">{{ product.description }}</p>
                <button @click="$parent.get_product(product.id)" class="view-btn p-3 d-block btn-block"
                        style="border-radius: unset">{{ $t("products.view_details") }}
                </button>

              </div>
            </div>
          </div>
        </div>
        <div class="name-wrapper">
          <h1 @click="$parent.get_category(category.id)" class="text-center mt-3 lalezar-regular card-name">{{  product.name }}</h1>
        </div>
      </div>

    </template>


  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "products_card",
  props: ['products'],
  computed: {
    ...mapGetters(['get_css_direction_classes']),
    is_small_screen() {
      return window.innerWidth <= 768;
    },
  }
}
</script>

<style scoped>

</style>