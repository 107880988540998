<template>
  <section id="clients" class="pt-page pt-page-5" data-id="clients">
    <div class="container">
      <div class="row d-flex align-content-lg-center min-height-100vh">

        <!-- Features Box-->
        <div class="col-lg-4 pr-lg-5 p-0">
          <div class="heading-area" :class="get_css_direction_classes">
            <h6 class="sub-title main-color">{{  $t("clients.get_to_know_our_clients") }}</h6>
            <h5 class="title lalezar-regular">{{  $t("clients.our_clients_and_faq") }}</h5>
            <p class="paragraph">
              {{  $t("clients.line_1") }}
              </p>
          </div>
          <div id="client-nav"></div>
        </div>

        <!-- Client Area-->
        <div class="col-lg-8" v-if="faq.length > 0">
          <div id="owl-client" class="owl-carousel owl-theme">
            <!--Items-->
            <div v-for="(faq, index) in faq" :key="index" class="client-box">
              <h4 class="custom-primary-green-color lalezar-regular">{{  faq.question }}</h4>
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>

        <!--Partner Slider-->
        <div class="owl-carousel partners-slider">
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/0.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/1.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/2.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/3.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/4.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/5.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/6.png"></div>
          <div class="logo-item"><img alt="client-logo" src="/static/images/clients/7.png"></div>

        </div>

      </div>
    </div>
  </section>


</template>

<script>
import $ from '../../../static/vendor/js/bundle.min';
import {mapGetters} from "vuex";

export default {
  name: "clients_main",
  computed: {
    ...mapGetters(['get_css_direction_classes']),
  },
  data() {
    return {
      faq: [],

    }
  },
  methods: {
    get_faq() {
      $.ajax({
        type: 'GET',
        url: '/api/faq/get_faq',
        dataType: 'json',
        success: (response) => {
          this.faq = response;
        },
      });

    },

  },
  created() {
    this.get_faq();
  }
}
</script>

<style scoped>

</style>