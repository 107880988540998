<template>
  <section id="home" class="pt-page pt-page-1" data-id="home">


    <div class="container slider-container">
      <div class="row align-items-lg-center">

        <!-- Image Area -->
        <div class="col-lg-5 order-lg-2">
          <div class="intro row">
            <img src="/static/images/home/main_pic.png" class="circle-main col-12" alt="main">

            <div class="col-sm-12  d-none d-lg-flex  justify-content-center sub-images">
              <img src="/static/images/home/left_pic.png" class="circle-1" width="200" alt="left">
              <img src="/static/images/home/center_pic.png" class="circle-2" width="200" alt="center">
              <img src="/static/images/home/right_pic.png" class="circle-3" width="200" alt="right">
            </div>
          </div>
        </div>

        <!-- Text Area -->
        <div class="col-lg-7 wow fadeInRight" >
          <!-- Box Area -->
          <div class="heading-box">
            <p v-html="$t('home.company_name')" class="company-name lalezar-regular" :class="get_css_direction_classes"></p>
            <p  v-html="$t('home.intro')" class="company-description noto-kufi-regular" :class="get_css_direction_classes"></p>

            <a href="#about" data-text="Read More" class="read-more-btn" >{{ $t("home.read_more") }}</a>
          </div>
        </div>

      </div>

      <!-- Shapes -->
      <span class="shape-square"></span>
      <span class="shape-triangle"></span>
      <span class="shape-plus"></span>
      <span class="shape-circle"></span>

    </div>
  </section>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "home_main",
  computed: {
    ...mapGetters(['get_css_direction_classes'])
  },
}
</script>

<style scoped>
.company-description{
  line-height: 3.3rem !important;
}

.sub-images {
  transform: translateY(-40%);
  gap: 3rem;
}

.circle-1 {
  transform: translateY(-50%);
  animation: dropAndRise-1 15s ease-in-out infinite;

}

.circle-2 {
  transform: translateY(10%);
  animation: dropAndRise-2 15s ease-in-out infinite;

}


.circle-3 {
  transform: translateY(-20%);
  animation: dropAndRise-3 15s ease-in-out infinite;

}


.circle-1 {
  animation-delay: 1s;
}

.circle-2 {
  animation-delay: 2s;
}

.circle-3 {
  animation-delay: 3s;
}

@keyframes dropAndRise-1 {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-50%);
  }
}


@keyframes dropAndRise-2 {
  0% {
    transform: translateY(10%);
  }
  50% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(10%);
  }
}


@keyframes dropAndRise-3 {
  0% {
    transform: translateY(-20%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-20%);
  }
}


.read-more-btn {
  font-family: "Montserrat", sans-serif;
  background: var(--main-green-color);
  font-weight: bold;
  color: #fff;
  padding: 15px;
  margin-top: 1rem;
  width: 180px;
}


.read-more-btn:hover {
  background: #aae348;

}
</style>