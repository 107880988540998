<template>
  <div class="product-details">
    <h2 class="mt-5 text-center text-success"> {{ product.name }}</h2>

    <div class="row mt-5">

      <!-- Images Box-->
      <div class="col-sm-12 col-md-6">
        <!--Portfolio Items-->
        <div id="js-grid-mosaic-flat" class="cbp cbp-l-grid-mosaic-flat">
          <!-- Item -->
          <div v-for="image in get_sub_images" :key="image.id" class="cbp-item">
            <a :href="image.image" class="cbp-caption cbp-lightbox">
              <div class="cbp-caption-defaultWrap">
                <img :src="image.image" alt="img"  width="350" height="350">
              </div>
            </a>
          </div>
        </div>
      </div>


      <div class="col-sm mt-5">
        <table class="table table-striped" style="direction: rtl;">
          <tbody>
          <tr v-for="info in product.productinfo_set" :key="info.id">
            <th scope="row">{{ info.name }}</th>
            <td>{{ info.value }}</td>
          </tr>
          </tbody>
        </table>

        <p class="mt-5">{{ product.description }}</p>

      </div>
    </div>

  </div>
</template>


<script>

export default {
  name: "product_details",
  props: ['product'],
  computed: {
    get_main_image() {
      return this.product.productimage_set[0] || '';
    },
    get_sub_images() {
      return this.product.productimage_set.slice(1);
    },
  },
}
</script>

<style scoped>
</style>