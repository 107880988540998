export default {
    company_name: "Gulf Paper Company L.C",
    sidebar: {
        home: "Home",
        products: "Products",
        services: "Services",
        clients: "Clients",
        Contact: "Contact",
        about_us: "About Us",
    },
    home: {
        name: "Home",
        company_name: `GULF PAPER <span class="main-color">Company </span> <span> L.C </span>`,
        intro: `
            We take pride in offering a diverse range of printed  <span class="main-color"> Stickers </span>
             and <span class="main-color">Thermal Papers</span> to meet your various needs.`,
        read_more: "Read More >>",
    },
    products: {
        name: "Products",
        search_placeholder: "Search for a Product",
        view_details: "View Details",
        intro: {
            our_products: "Our Products",
            line_1: "We pay attention to details to deliver unique and customized products that align with your vision.",
        },
    },
    services: {
        our_services: "Our Services",
        manufacturing: "Manufacturing",
        printing: "Printing",
        quality_and_technology: "Quality and technology",
        customization: "Customization",
        sustainability: "Sustainability and environment",
        support_and_service: "Support and service",

        Customer: "customer",
        Projects: "projects",

        card_1: {
            line_1: "In our factory, we specialize in manufacturing paper, thermal, and plastic labels, as well as thermal paper, offering a wide range of sizes and materials to meet all our customers' needs.",
            line_2: " We guarantee high-quality products designed to enhance your brand identity and improve the efficiency of your operations.",
        }

    },
    clients: {
        get_to_know_our_clients: "Get to Know Our Clients",
        our_clients_and_faq: "Our Clients & FAQ",
        line_1: "We work closely with you to ensure your expectations are met and satisfaction is achieved.",
    },
    contact: {
        message_sent_successfully: "Your Message Sent Successfully We will Reply to you as Soon as Possible",
        ok: "OK",
        have_any_question: "Have any Question ?",
        lets_get_in_touch: "Let's Get In Touch",
        send_message: "Send Message",
        address: "Address",
        address_body: "Saudi Arabia - Riyadh - Al-Kharj Road, Exit 5, Al-Rafaya Industrial Area.",
        email: "email",
        phone: "phone",
        name: "Name",
        subject: "Subject",
        message: "Message",
    },
    about: {
        line_1: {
            head: "Who We Are",
            body: "Welcome to Gulf Papers Limited, leaders in the manufacturing and printing of label rolls and thermal papers. Our company was established on January 1, 2024, with a vision to provide innovative and high-quality printing and manufacturing solutions that meet the growing market demands. We are committed to delivering products characterized by precision and efficiency, utilizing the latest technologies in the industry and printing. Since our inception, we have strived to be the first choice for our customers, and we are now working on expanding our footprint in regional and international markets, driven by our passion for quality and innovation.",
        },
        line_2: {
            head: "Our Story",
            body: "Our company began its journey in the heart of the Kingdom of Saudi Arabia, driven by the vision of 2030 to achieve excellence in industry and printing. We use the latest technologies and the best materials to ensure the production of high-quality label rolls and thermal papers. We aim to be successful partners for our customers, providing products that enhance their brand identity and improve the efficiency of their operations.",
        },
        line_3: {
            head: "Our Mission",
            body: "Our mission is to provide innovative solutions that help our customers achieve their strategic goals.",
            body_2: "We strive to be successful partners for our customers, offering services and products characterized by high quality and operational efficiency.",
            why_choose_gulfpaper: "Why Choose Gulf Papers Limited?",
            why_1: " - Product Customization: We offer customized products in various sizes to meet our customer's unique needs.",
            why_2: " - High Quality: Our products reflect our commitment to exceptional quality and attention to detail.",
            why_3: " - Innovation and Technology: We use the latest technologies in the manufacturing and printing of labels and thermal papers.",
        },
        line_4: {
            head: "Contact Us",
            body: "We invite you to contact us to explore how we can support your business with our advanced solutions. The team at Gulf Papers Limited is always ready to provide the advice and support necessary to ensure your success.",
        },
    },

    pagination: {
        previous: "Previous",
        next: "Next",
    },
};
