<template>

  <section id="about" class="pt-page pt-page-7" data-id="about-us">
    <div class="container" :class="get_css_direction_classes">

      <h3 class="custom-primary-green-color">{{ $t("about.line_1.head") }}</h3>
      <p>{{ $t("about.line_1.body") }}</p>


      <h3 class="custom-primary-green-color mt-3">{{ $t("about.line_2.head") }}</h3>
      <p>{{ $t("about.line_2.body") }}</p>


      <h3 class="custom-primary-green-color mt-3">{{ $t("about.line_3.head") }}</h3>
      <p>{{ $t("about.line_3.body") }}
        <br>
        {{ $t("about.line_3.body_2") }}</p>


      <h3 class="custom-primary-green-color">{{ $t("about.line_3.why_choose_gulfpaper") }}</h3>
      <p>
        {{ $t("about.line_3.why_1") }}
        <br>
        {{ $t("about.line_3.why_2") }}
        <br>
        {{ $t("about.line_3.why_3") }}
      </p>


      <h3 class="custom-primary-green-color mt-3">{{ $t("about.line_4.head") }}</h3>
      <p>{{ $t("about.line_4.body") }}</p>

    </div>
  </section>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "about_main",
  computed: {
    ...mapGetters(['get_css_direction_classes']),
  },
}
</script>

<style scoped>
*{
  font-family: "Lalezar", system-ui, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 35px;
}

</style>