<template>
  <div class="mt-5 mb-5">

    <a href="#" @click="$parent.get_categories()">
      Home
    </a>
    <span v-if="routes.length > 0">/</span>


    <template v-for="(route, index)  in get_sorted_routes">
      <a @click="$parent.get_category(route.id)" href="#" :key="route.id">
        <bdi>{{ route.name }}</bdi>
      </a>
      <span v-if="routes.length -1 !== index" :key="index"> / </span>
    </template>


  </div>
</template>

<script>


export default {
  name: "products_navigation",
  props: {
    routes: {
      type: Array,
      required: [],
    },
  },
  data() {
    return {}
  },
  computed: {
    get_sorted_routes() {
      return [...this.routes].sort((a, b) => a.id - b.id)
    }
  },
  methods: {}

}
</script>

<style scoped>
a{
  font-size: 1.5rem;
}
</style>