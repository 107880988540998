<template>
  <section id="products" class="pt-page pt-page-2" data-id="products">
    <div class="products-wrapper">
      <!-- NAVBAR Component -->
      <div class="m-5 row justify-content-center">
        <nav_bar/>
      </div>
      <products_intro v-show="!product.id"/>

      <products_navigation :routes="catalog_path"/>
      <product_details v-if="product.id" :product="product"/>

      <div v-show="!product.id" class="cards-wrapper">
        <categories_card v-if="categories.results.length > 0" :categories="categories.results"/>
        <categories_card v-if="category.subcategories.results.length > 0" :categories="category.subcategories.results"/>
        <products_card :products="category.product_set"/>
      </div>


      <div v-if="!product.id" class="pagination d-flex justify-content-center w-100" style="gap: 15px;">
        <button class="page-btn">{{  $t("pagination.previous") }}</button>

        <button
            v-for="n in range(1, categories.total_pages || category.subcategories.total_pages)"
            :key="n"
            @click="get_categories(n)"
            class="page-item"
            :class="{'page-active':  n === categories.page} ">
          {{ n }}
        </button>

        <button class="page-btn">{{  $t("pagination.next") }}</button>
      </div>

    </div>
  </section>

</template>

<script>
import jquery from '@/js/jquery';

import nav_bar from "@/components/nav_bar"
import products_intro from "@/components/products/products_intro";
import products_navigation from "@/components/products/products_navigation";
import categories_card from "@/components/products/categories_card";
import products_card from "@/components/products/products_card";
import product_details from "@/components/products/product_details";

export default {
  name: "products_main",
  components: {
    nav_bar,
    products_intro,
    products_navigation,
    categories_card,
    products_card,
    product_details
  },
  data() {
    return {
      catalog_path: [],
      product: {
        id: null,
      },
      categories: {
        "next": null,
        "previous": null,
        "count": 1,
        "page": 1,
        "total_pages": 1,
        "results": []
      },
      category: {
        id: null,
        get_path: [],
        subcategories: {
          "next": null,
          "previous": null,
          "count": 1,
          "page": 1,
          "total_pages": 1,
          "results": []
        },
        product_set: [],
      }
    }
  },
  methods: {
    range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    },
    reset_data() {
      this.catalog_path = [];
      this.product = {id: null,};
      this.categories = {
        "next": null,
        "previous": null,
        "count": 1,
        "page": 1,
        "results": []
      };
      this.category = {
        id: null,
        get_path: [],
        subcategories: {
          "next": null,
          "previous": null,
          "count": 1,
          "page": 1,
          "total_pages": 1,
          "results": []
        },
        product_set: [],
      }

    },
    get_categories(page_num) {
      jquery.ajax({
        type: 'GET',
        url: `/api/products/get_categories?page=${page_num ? page_num : this.categories.page}`,
        dataType: 'json',
        success: (response) => {
          this.reset_data();
          this.categories = response;
        },
      });
    },
    get_category(category_pk) {
      jquery.ajax({
        type: 'GET',
        url: `/api/products/get_category/${category_pk}`,
        dataType: 'json',
        success: (response) => {
          this.reset_data();
          // response_blueprint = {
          //   "parent": null,
          //   "id": 1,
          //   "name": "ملابس",
          //   "description": "Simply dummy text of the print and typesetting industry lorem Ipsum has been standard.",
          //   "image": "http://localhost:8001/media/group_images/0.png",
          //   "get_path": [],
          //   "product_set": [
          //     {
          //       "id": 1,
          //       "name": "تيشرت",
          //       "description": "شسيشسي",
          //       "priority": 0,
          //       "is_deleted": false,
          //       "created_date": "2024-02-04T18:40:37.449421Z",
          //       "updated_date": "2024-02-04T18:49:21.681421Z",
          //       "category": 1
          //     }
          //   ]
          // }
          this.category = response;
          this.catalog_path = this.category.get_path;
        },
      });
    },
    get_product(product_pk) {
      this.reset_data();
      jquery.ajax({
        type: 'GET',
        url: `/api/products/get_product/${product_pk}`,
        dataType: 'json',
        success: (response) => {
          this.reset_data();
          this.product = response;
          this.catalog_path = response.get_path;
        },
      });
    },
  },
  created() {
    this.get_categories();
  }
}
</script>

<style scoped>

.pagination {
  margin-top: 15rem;
}

.page-item {
  position: relative;
  background: var(--main-green-color);
  padding: 10px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 1.7rem;
}

.page-btn {
  width: 90px;
  background: var(--main-green-color);

}

.page-item.page-active {
  border: 2px solid #fff;
}

.page-active {
  box-shadow: 0 0 0 5px var(--main-green-color);

}


.page-item, .page-btn {
  color: white;
  border: none;
  outline: none;
  transition: all .5s ease-in-out;
}

.page-item:hover, .page-btn:hover {
  background: #aae348;
}


</style>