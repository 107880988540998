import VueI18n from "vue-i18n";

import Vue from "vue";
Vue.use(VueI18n);

const messages = {
  ar: require("@/assets/translations/ar").default,
  en: require("@/assets/translations/en").default,
};

export default new VueI18n({
  locale: "ar", // default locale
  messages,
});
