import Vue from 'vue'
import App from './App.vue'
import store from './store';

import 'vue-instant/dist/vue-instant.css'
import VueInstant from 'vue-instant/dist/vue-instant.common'

import i18n from "@/i18n";


Vue.config.productionTip = false

Vue.use(VueInstant)

new Vue({
    render: h => h(App),
    store,
    i18n,
    beforeCreate() {
        // Set the initial locale
        this.$i18n.locale = this.$store.getters.get_language;
    },
}).$mount('#app')
