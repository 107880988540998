import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        lang: localStorage.getItem("lang") || 'ar',
    },
    actions: {
        set_language({commit}, lang) {
            commit("set_language", lang);
        },
    },
    mutations: {
        set_language: (state, lang) => {
            state.lang = lang;
            localStorage.setItem("lang", lang);
        },
    },
    getters: {
        get_language: (state) => {
            return state.lang;
        },
        get_page_direction: (state) => {
            switch (state.lang) {
                case "ar":
                    return "rtl";
                case "en":
                    return "ltr";
                default:
                    return "ltr";
            }
        },
        get_css_direction_classes: (state) => {
            switch (state.lang) {
                case 'ar':
                    return 'arabic'
                case 'en':
                    return 'english'
            }
        }
    },
    modules: {},
});
