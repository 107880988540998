<template>
  <section id="contact" class="pt-page pt-page-6" data-id="contact">

    <!-- Button trigger modal -->
    <button type="button" id="contact-modal-success-btn" class="btn btn-primary d-none" data-toggle="modal"
            data-target="#contact-modal-success">
      Contact success modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="contact-modal-success" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="text-center">
              {{ $t("contact.message_sent_successfully") }}
            </h5>

            <div class="icon row justify-content-center">

              <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
              <svg width="150px" height="150px" viewBox="0 0 117 117" xmlns="http://www.w3.org/2000/svg">

                <title/>

                <desc/>

                <defs/>

                <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">

                  <g fill-rule="nonzero" id="correct">

                    <path
                        d="M34.5,55.1 C32.9,53.5 30.3,53.5 28.7,55.1 C27.1,56.7 27.1,59.3 28.7,60.9 L47.6,79.8 C48.4,80.6 49.4,81 50.5,81 C50.6,81 50.6,81 50.7,81 C51.8,80.9 52.9,80.4 53.7,79.5 L101,22.8 C102.4,21.1 102.2,18.5 100.5,17 C98.8,15.6 96.2,15.8 94.7,17.5 L50.2,70.8 L34.5,55.1 Z"
                        fill="#17AB13" id="Shape"/>

                    <path
                        d="M89.1,9.3 C66.1,-5.1 36.6,-1.7 17.4,17.5 C-5.2,40.1 -5.2,77 17.4,99.6 C28.7,110.9 43.6,116.6 58.4,116.6 C73.2,116.6 88.1,110.9 99.4,99.6 C118.7,80.3 122,50.7 107.5,27.7 C106.3,25.8 103.8,25.2 101.9,26.4 C100,27.6 99.4,30.1 100.6,32 C113.1,51.8 110.2,77.2 93.6,93.8 C74.2,113.2 42.5,113.2 23.1,93.8 C3.7,74.4 3.7,42.7 23.1,23.3 C39.7,6.8 65,3.9 84.8,16.2 C86.7,17.4 89.2,16.8 90.4,14.9 C91.6,13 91,10.5 89.1,9.3 Z"
                        fill="#4A4A4A" id="Shape"/>

                  </g>

                </g>

              </svg>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t("contact.ok") }}</button>
          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="row align-items-lg-center">
        <!--Form area-->
        <div class="col-lg-6 pr-lg-5">
          <div class="heading-area" :class="get_css_direction_classes">
            <h1 class="sub-title main-color lalezar-regular">{{ $t("contact.have_any_question") }}</h1>
            <h6 class="title lalezar-regular" style="font-size: 2.4rem">{{ $t("contact.lets_get_in_touch") }}</h6>
          </div>
          <!--Contact Form-->
          <form class="contact-form" id="contact-form-data" @submit.prevent="send_email"
                :class="get_css_direction_classes">

            <div class="row">
              <div class="col-sm-12" id="result"></div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input class="form-control" type="text" :placeholder="$t('contact.name')" required="" id="sender-name"
                         name="sender_name">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input class="form-control" type="email" :placeholder="$t('contact.email')" required=""
                         id="sender-email"
                         name="sender_email">
                </div>
              </div>
            </div>

            <div class="form-group">
              <input class="form-control" type="text" :placeholder="$t('contact.subject')" required=""
                     id="sender-subject"
                     name="subject">
            </div>

            <div class="form-group">
              <textarea class="form-control" :placeholder="$t('contact.message')" id="sender-message" name="question"
                        required></textarea>
            </div>

            <!--Button-->
            <button type="submit" id="submit_btn"
                    class="btn btn-large btn-rounded btn-green d-block mt-4 contact_btn w-100 lalezar-regular">
              <i class="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true"></i>{{ $t("contact.send_message") }}
            </button>
          </form>

          <!-- Address -->
          <ul class="address-item" :class="get_css_direction_classes">
            <!--item-->
            <li class="w-100 mb-4">
              <i class="lni-apartment main-color"></i>
              <div class="content">
                <h6 class="main-color m-0 lalezar-regular">{{ $t("contact.address") }}</h6>
                <p class="lalezar-regular"> {{ $t("contact.address_body") }} </p>
              </div>
            </li>
            <!--item-->
            <li class="pr-2">
              <i class="lni-comment-reply main-color"></i>
              <div class="content">
                <h6 class="main-color m-0">{{ $t("contact.email") }}:</h6>
                <p class="lalezar-regular"><a href="mailto:info@gulfp.net">info@gulfp.net</a></p>
              </div>
            </li>
            <!--item-->
            <li>
              <i class="lni-phone-handset main-color"></i>
              <div class="content lalezar-regular">
                <h6 class="main-color m-0">{{ $t("contact.phone") }}</h6>
                <p class="lalezar-regular"><a href="tel:002343474383">+966 548013040</a></p>
              </div>
            </li>

          </ul>

        </div>

        <!--map area-->
        <div class="col-lg-6" style="box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3636.1849084521705!2d47.15680760000001!3d24.3051845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ff5b5f322e75b%3A0xdc88541ec1f8078e!2z2LTYsdmD2Kkg2KfZiNix2KfZgiDYp9mE2K7ZhNmK2Kwg2KfZhNmF2K3Yr9mI2K_YqQ!5e0!3m2!1sen!2seg!4v1707151927495!5m2!1sen!2seg"
              width="600" height="650" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>


      <div class="social-menu">
        <ul class="row">
          <li>
            <a href="https://www.instagram.com/gulf_pf?igsh=MXdxY2NiNWFlYzEybQ==" target="blank"><i
                class="fab fa-instagram insta-color"></i></a>
          </li>

          <li>
            <a href="https://www.snapchat.com/add/gulf_pf?share_id=y0VO7MniJYw&locale=ar-EG" target="blank">
              <i class="fab fa-snapchat snap-color"></i>
            </a>
          </li>

          <li>
            <a href="https://www.tiktok.com/@gulf_pf" target="blank"
               class="row align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
              </svg>
            </a>
          </li>

          <li>
            <a href="https://twitter.com/Gulf_PF" target="blank">
              <i class="fab fa-twitter twitter-color"></i>
            </a>
          </li>

          <li>
            <a href="https://www.facebook.com/gulf.pf" target="blank">
              <i class="fab fa-facebook fb-color"></i>
            </a>
          </li>
        </ul>
      </div>

    </div>
  </section>


</template>

<script>
import $ from '../../../static/vendor/js/bundle.min';
import {mapGetters} from "vuex";

export default {
  name: "clients_main",
  computed: {
    ...mapGetters(['get_css_direction_classes']),
  },
  methods: {
    test() {
      console.log("TST clicked !!")
    },
    send_email() {
      console.log("contact_form: ", $)
      // debugger;// eslint-disable-line no-debugger
      // return 0;

      const contact_form = $('#contact-form-data');
      console.log("contact_form: ", contact_form)
      const formData = contact_form.serialize();

      $.ajax({
        type: 'POST',
        url: '/api/emails/new_email',  // The URL to your Django view
        data: formData,
        dataType: 'json',
        success: function () {
          $('#contact-modal-success-btn').click();
        },
        error: function (xhr, textStatus, errorThrown) {
          console.log(xhr, textStatus, errorThrown)
          if (xhr.status === 400) {
            // Handle form validation errors
            console.error('Validation Error:', xhr.responseJSON.errors);
          } else {
            // Handle other errors
            console.error('Error:', errorThrown);
          }
        },
        complete: function () {
          contact_form[0].reset();
        }
      });
    },

  }
}
</script>

<style scoped>
.social-menu {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.social-menu ul li {
  list-style: none;
  margin: 10px 15px;
}

.social-menu ul li a {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: .6s;
  /*box-shadow: 0 5px 4px rgba(0, 0, 0, .5);*/
  box-shadow: 0 3px 4px rgba(0, 0, 0, .5);

}

.social-menu ul li a:hover {
  background-color: var(--main-green-color);
}

.social-menu ul li a:hover {
  transform: translate(0, -10%);
}

.social-menu ul li .fab {
  font-size: 30px;
  line-height: 60px;
  transition: .3s;
  /*color: #000;*/
}

.social-menu ul li a svg {
  margin-top: 25%;
  transition: .3s;
  color: #000;
  width: 30px;
  height: 30px;
}


.insta-color {
  color: #fccc63;
}


.snap-color {
  color: #FFFF00;
}


/*.tiktok-color {*/
/*  color: #1DA1F2;*/
/*}*/


.twitter-color {
  color: #1DA1F2;
}

.fb-color {
  color: #316FF6;
}

.address-item.arabic i {
  margin-left: 20px;
}


.address-item.english i {
  margin-right: 20px;
}
</style>